#hero {
  padding-bottom: 0;
  background: linear-gradient(180deg, #EDFFFD 0%, rgba(237, 255, 253, 0) 100%);
}

main > section, #disburse-pane {
  padding: 6.5% 48px;
  min-height: 500px;
}

#disburse-pane {
  min-height: unset;
}

main > section img.display-image {
  max-height: 400px;
}

main .green {
  background-color: #002C27;
  background-repeat: repeat-x;
  background-size: contain;
  background-image: url("./assets/images/View-Area-BG.svg");
}

main #how-it-works .card {
  max-width: calc(50% - 10px);
}

main #about .card {
  max-width: calc(33.33% - 1rem);
}

main #benefits .card {
  min-width: 200px;
  max-width: 280px;
}

@media screen and (max-width: 768px) {
  main > section, #disburse-pane {
    padding: 48px 36px;
    height: auto;
  }

  main #how-it-works .card {
    max-width: 100%;
  }

  main #about .card {
    max-width: calc(50% - 1rem);
  }

  main #benefits .card {
    max-width: unset;
  }
}

@media screen and (max-width: 600px) {
  main > section, #disburse-pane {
    padding: 40px 28px;
  }

  main #about .card {
    max-width: unset;
  }
}
